import Button from "antd/es/button"
import Divider from "antd/es/divider"
import Empty from "antd/es/empty"
import { collection } from "firebase/firestore"
import { LoaderCircleIcon, PlusIcon } from "lucide-react"
import { micromark } from "micromark"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom"

import Header from "../components/Header"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import type { StaticCardContent } from "../live/types"
import { STATIC_CARD_CONTENT_COLLECTION } from "../live/types"
import { GROUPS_COLLECTION } from "../types/common"

const CardPreview: React.FC<{ card: StaticCardContent }> = ({ card }) => {
  const htmlContents = micromark(card.content)
  const contentHtml = (
    <div
      className="w-full"
      dangerouslySetInnerHTML={{ __html: htmlContents }}
    />
  )

  return (
    <div className="rounded-xl border p-2">
      <Link to={card.oid}>
        <h4>Condition Prompt</h4>
        <p>{card.conditionPrompt}</p>
      </Link>
      <Divider />
      <h4>Card Content</h4>
      <div className="rounded-xl border p-2">{contentHtml}</div>
    </div>
  )
}

const CardsList: React.FC = () => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [cards, cardsLoading] = useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      STATIC_CARD_CONTENT_COLLECTION,
    ).withConverter(makeConverter<StaticCardContent>()),
  )

  return (
    <div className="p-4">
      {cardsLoading ? (
        <LoaderCircleIcon
          className="text-primary mt-4 w-full animate-spin"
          size={64}
        />
      ) : cards?.length === 0 ? (
        <Empty description="No cards yet, click Add Card to create one" />
      ) : (
        cards?.map((card) => <CardPreview key={card.oid} card={card} />)
      )}
    </div>
  )
}

const LiveStaticCardsPage: React.FC = () => {
  return (
    <>
      <Header
        title="Live Cards"
        breadcrumbs={[
          {
            title: "Live Assistant",
            href: "/live-assistant",
          },
          {
            title: "Cards",
            href: "/live-assistant/cards",
          },
        ]}
      >
        <Link to="/live-assistant/cards/new">
          <Button icon={<PlusIcon />} type="primary">
            Add Card
          </Button>
        </Link>
      </Header>
      <CardsList />
    </>
  )
}

export default LiveStaticCardsPage
