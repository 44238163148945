import type { AnalyticsEventProduct } from "./analytics/types"

export const getGoogleOauthClientId = (): string => {
  return import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID || ""
}

export const getFrontendUrl = (): string => {
  return window.location.origin
}

// Constants to avoid rerendering with defaults.
export const VOID_FUNCTION = () => {}
export const VOID_ASYNC_FUNCTION = async () => {}
export const IDENTITY_FUNCTION = <T>(x: T) => x
export const EMPTY_ARRAY = []
export const EMPTY_OBJ = {}

// This is safe.
export const SAFE_EMPTY_URL = "javascript:;"

export const TERMS_URL = "https://quilt.app/terms"
export const PRIVACY_URL = "https://quilt.app/privacy-policy"
export const DATA_USE_DISCLOSURE_URL = "https://quilt.app/data-use-disclosure"
export const SUPPORT_URL = "https://support.quilt.app"

export const EXTENSION_ID = import.meta.env.VITE_EXTENSION_ID
export const EXTENSION_URL = `https://chromewebstore.google.com/detail/quilt/${EXTENSION_ID}`
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_WEB_APP_DSN

export const GOOGLE_EMAIL_OAUTH_SCOPE =
  "https://www.googleapis.com/auth/userinfo.email"

export const GOOGLE_DRIVE_DOCUMENTS_SCOPES = [
  "https://www.googleapis.com/auth/drive.readonly",
]

export const GOOGLE_SPREADSHEETS_OAUTH_SCOPE =
  "https://www.googleapis.com/auth/spreadsheets"

export const GOOGLE_DRIVE_DOCUMENTS_READONLY_OAUTH_SCOPE =
  "https://www.googleapis.com/auth/documents.readonly"

export const ANALYTICS_PRODUCT: AnalyticsEventProduct = "WEB"

export const QUILT_EMAIL_ADDRESS = "support@quilt.app"
