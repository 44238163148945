import { useParams } from "react-router"

import Header from "../components/Header"
import StaticCardEditor from "../live/StaticCardEditor"

const LiveStaticCardEditorPage: React.FC = () => {
  const { staticCardContentOid } = useParams<{
    staticCardContentOid: string
  }>()
  if (!staticCardContentOid) {
    throw new Error("staticCardContentOid is required")
  }

  return (
    <>
      <Header
        title={`Card Editor`}
        breadcrumbs={[
          {
            title: "Live Assistant",
            href: "/live-assistant",
          },
          {
            title: "Cards",
            href: "/live-assistant/cards",
          },
          {
            title: staticCardContentOid,
            href: `/live-assistant/cards/${staticCardContentOid}`,
          },
        ]}
      />
      <StaticCardEditor staticCardContentOid={staticCardContentOid} />
    </>
  )
}

export default LiveStaticCardEditorPage
