import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore"

import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import { GROUPS_COLLECTION } from "../types/common"
import type { StaticCardContent } from "./types"
import { STATIC_CARD_CONTENT_COLLECTION } from "./types"

const colRef = (groupOid: string) =>
  collection(
    db,
    GROUPS_COLLECTION,
    groupOid,
    STATIC_CARD_CONTENT_COLLECTION,
  ).withConverter(makeConverter<StaticCardContent>())

// Returns the oid of the newly created card.
export const createStaticCard = async (
  groupOid: string,
  card: Omit<StaticCardContent, "oid">,
): Promise<string> => {
  const result = await addDoc(colRef(groupOid), card)

  return result.id
}

export const updateStaticCard = async (
  groupOid: string,
  card: StaticCardContent,
): Promise<void> => {
  await updateDoc(doc(colRef(groupOid), card.oid), card)
}

export const deleteStaticCard = async (
  groupOid: string,
  cardOid: string,
): Promise<void> => {
  await deleteDoc(doc(colRef(groupOid), cardOid))
}
